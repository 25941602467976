import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);


export let routes = [
    {
        path: '/',
        name: 'index',
        title:'首页',
        component: () => import('./pages/index'),
    },
    {
        path: '/about-us',
        name: 'about-us',
        title:'关于我们',
        component: () => import('./pages/about-us'),
    },
    {
        path: '/news',
        name: 'news',
        title:'新闻资讯',
        component: () => import('./pages/news'),
    },
    {
        path: '/business',
        name: 'business',
        title:'公司业务',
        component: () => import('./pages/business'),
    },
    {
        path: '/new-details',
        name: 'new-details',
        title:'新闻详情',
        component: () => import('./pages/new-details'),
    }
];

let router = new VueRouter({
        routes: routes,
        scrollBehavior: () => ({y: 0}),
    }
);

export default router;