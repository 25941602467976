import Vue from 'vue'
import App from './App.vue'
import router from  './router';
Vue.config.productionTip = false;
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "animate.css"
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css';
import sticky from 'vue-sticky-header';
import axios from "axios";

Vue.use(sticky)
Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper);
Vue.prototype.$axios=axios;
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
